<template>
    <div class="container">

        <!-- Device -->
        <div class="card card-body" v-if="assetDevice">
            <h5>Device</h5>
            <div class="media">
                <div class="media-left">
                    <icon-mobile v-if="!selectedAssetColour || !selectedAssetColour.image_path" size="2x" class="icon-all-muted"></icon-mobile>
                    <img v-if="selectedAssetColour && selectedAssetColour.image_path" :src="selectedAssetColour.image_path" class="img-2x">
                </div>
                <div class="media-body">
                    <h5 class="mb-2">{{ assetDevice.model_version_title }} {{ assetDevice.colour_title }} ({{ assetDevice.board_type_title }})</h5>
                    <p class="mb-0 text-muted text-sm">IMEI: {{ assetDevice.imei }}</p>
                </div>
                <div class="media-right">
                    <router-link :to="{name: 'bulkRepairDiagnostics.device'}" class="btn btn-info btn-block btn-sm">Edit</router-link>
                </div>
            </div>
        </div>

        <!-- Selected Problems -->
        <div class="card card-body" v-if="addedAssetProblems">
            <h5>Selected Problems</h5>
            <div class="media">
                <div class="media-body">
                    <dl class="mb-0">
                        <dt>Problems</dt>
                        <dd v-for="problem in addedAssetProblems" :key="problem.id">{{ problem.title }}</dd>
                    </dl>
                </div>
                <div class="media-right">
                    <router-link :to="{name: 'bulkRepairDiagnostics.confirmProblems'}" class="btn btn-info btn-block btn-sm">Edit</router-link>
                </div>
            </div>
        </div>

        <button class="btn btn-success btn-lg btn-block" @click="confirmDiagnosis()" :disabled="confirming"><i class="fa fa-pulse fa-spinner" v-if="confirming"></i> Complete Diagnostic</button>
        <button class="btn btn-link btn-sm btn-block mt-5" @click="printAssetLabel(assetId)">Reprint asset label</button>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: ['assetId'],

        data() {
            return {
                confirming: false
            }
        },

        mounted() {
            this.loadAsset({
                assetId: this.assetId
            }).then(() => {
                this.loadAssetDevice({
                    assetId: this.assetId,
                    ownerId: this.asset.owner_id,
                });
            });
        },

        computed: {
            ...mapGetters([
                'addedAssetProblems',
                'asset',
                'assetDevice',
                'isTechnician',
                'user',
                'selectedAssetColour'
            ]),

            technicianId() {
                if (this.isTechnician && this.user.technician && this.user.technician.id) {
                    return this.user.technician.id;
                }
                return null;
            }
        },

        methods: {
            ...mapActions([
                'displayToast',
                'loadAddedAssetProblems',
                'loadAsset',
                'loadAssetDevice',
                'printAssetLabel',
                'setAssetDiagnosticComplete'
            ]),

            /** Complete the diagnosis */
            confirmDiagnosis() {
                if (this.confirming) return;

                this.confirming = true;

                // Default to Placeholder Technician ID for in house repairs
                let technicianId = (this.technicianId) ? this.technicianId : 106;

                this.setAssetDiagnosticComplete({
                    assetId: this.assetId,
                    technicianId: technicianId
                }).then(() => {
                    // if (
                    //     this.asset.asset_type === 'Customer Mail-in' ||
                    //     this.asset.status_code === 'pending-in-house-repair' ||
                    //     this.asset.status_code === 'requires-re-repair'
                    // ) {
                        this.$router.push({name: 'bulkRepairDiagnostics.nextSteps'});
                        this.confirming = false;
                        // return;
                    // }

                    // this.$router.push({name: 'bulkRepairImeiSearch', params:{diagnosticsComplete: true}});
                }).catch(error => {
                    this.displayToast({text: error, type: 'error'});
                    this.confirming = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>